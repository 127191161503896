<template>
    <div class="card-send-success-container" :style="{height:scrollHeight+'px'}">
        <div class="success-icon-container">
            <div class="icon">
                <img src="https://api.mengjingloulan.com/storage/wechat/new-user-center/send-success-icon.png"/>
            </div>
            <div class="status-text">
                支付失败
            </div>
            <div class="success-text">{{this.text}}</div>
        </div>

        <div class="detail-buttons">
            <div class="buttons" @click="goToHomePage">
                <div class="use">
                    返回首页
                </div>
            </div>
            <div class="buttons" @click="contact">
                <div class="use-look">
                    联系客服
                </div>
            </div>
        </div>

    </div>
</template>

<script>
  import TitleRender from '@/components/common/title-render'

  export default {
    name: 'pay-success',
    components: {TitleRender},
    mounted () {
      let query = this.$route.query
      document.title = query.title || '支付失败'
      this.title = query.title
      this.text = query.text || '很抱歉！您的订单支付失败，请您联系客服'
      this.listTypeId = query?.listTypeId || 1
      this.order_id = query.order_id || 32
      this.getContactMobile()
    },
    data () {
      return {
        text: '恭喜您！付款成功',
        title: '支付成功',
        order_id: 0,
        scrollHeight: window.innerHeight,
        contactMobile: '',
        listTypeId: 1,
        canContinue: true
      }
    },
    methods: {
      getContactMobile () {
        this.$api.services.getContactMobile().then(response => {
          this.contactMobile = response.data
        })
      },
      goToHomePage () {
        if (this.canContinue) {
          this.canContinue = false

          let query = {listTypeId: this.listTypeId}
          let options = encodeURIComponent(JSON.stringify(query))
          this.$platform.wxsdk.wxRoute({
            type: 'redirectTo',
            url: '/web/services/home?query=' + options,
          })

          setTimeout(() => {
            this.canContinue = true
          }, 500)
        }

      },
      contact () {
        let self = this
        this.$dialog({
          title: '温馨提示',
          content: `确定要给${this.contactMobile}打电话吗？`,
          closeOnClickModal: false, //点击蒙层是否关闭对话框
          closeBtn: false, //显式右上角关闭按钮
          onOkBtn () {
            self.$dialog.closed()
            if (self.$platform.wxsdk.isWechat()) {
              self.$platform.wxsdk.wxRoute({
                type: 'navigateTo',
                url:
                '/web/makePhoneCall/common?mobile=' +
                this.contactMobile,
              })
            } else {
              window.location.href = 'tel:' + this.contactMobile
            }
          },
        })
      }
    }
  }
</script>

<style scoped lang="scss">
    .card-send-success-container {
        width: 100vw;
        min-height: 100vh;
        background: #F6F6F6;
        flex-wrap: wrap;
        overflow: auto;
        -webkit-overflow-scrolling: touch;

        .success-icon-container {
            width: 100%;
            margin-top: 85px;

            .icon {
                width: 100%;
                display: flex;
                justify-content: center;

                img {
                    width: 109px;
                    height: 109px;
                }
            }

            .status-text {
                width: 100%;
                font-size: 14px;
                color: #333333;
                font-weight: 600;
                display: flex;
                justify-content: center;
                margin-top: 23px;
            }

            .success-text {
                font-weight: 400;
                color: #939393;
                font-size: 12px;
                margin-top: 8px;
                width: 100%;
                display: flex;
                justify-content: center;
            }
        }

        .card-container {
            margin: 40px auto;
            margin-bottom: 0px;
            width: 345px;
            background: #FFFFFF;
            border-radius: 10px;

            .card-show-border {
                margin: auto;
                width: 300px;
                height: 0px;
                border-bottom: 1px solid #EBEBEB;

            }
            .card-show-list {
                margin: auto;
                width: 300px;
                display: flex;
                .show-list-label {
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #939393;
                    line-height: 20px;
                    width: 93px;
                    min-height: 44px;
                    display: flex;
                    align-items: center;

                }
                .show-list-content {
                    font-size: 14px;
                    font-family: PingFangSC-Semibold, PingFang SC;
                    font-weight: 600;
                    color: #333333;
                    line-height: 20px;
                    min-height: 44px;
                    width: 193px;
                    display: flex;
                    align-items: center;

                }
            }

        }
        .contact-service {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #F46A17;
            line-height: 14px;
            margin: 18px auto;
            width: 345px;
            text-align: right;
        }

        .detail-buttons {
            width: 345px;
            position: fixed;
            margin: 0 auto;
            left: 0;
            right: 0;
            bottom: 0;
            height: 60px;
            display: flex;
            align-items: center;
            justify-content: center;

            .buttons {
                flex: 1;
                display: flex;
                padding: 0 5px;
                border: 0px solid red;

                .use {
                    width: 168px;;
                    height: 48px;
                    background: #FFE6D5;
                    -webkit-border-radius: 6px;
                    -moz-border-radius: 6px;
                    border-radius: 6px;
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #F46A17;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
                .use-look {
                    width: 168px;;
                    height: 48px;
                    background: linear-gradient(139deg, #FAC484 0%, #F46A17 100%);
                    -webkit-border-radius: 6px;
                    -moz-border-radius: 6px;
                    border-radius: 6px;
                    font-size: 14px;
                    color: #FFFFFF;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    a {
                        color: #fff;
                    }
                }
            }
        }
    }
</style>